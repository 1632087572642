//
// your custom scss goes here
//
.btn-danger {
color: #fff!important;// MARC
background-color: #d34f2d;
border-color: #d34f2d;
}
.border-bottom-light {
	border-bottom: 1px solid $gray-100 !important;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus { // Modal
border-color: #a493f0!important;
}
.eyebrowlight {
display: inline-block;
font-size: .96rem;
font-weight: 400;
}
// Réduire les dimensions 2,5 à 2.0
.alert {
position: relative;
padding: 2.0rem 2.0rem!important;
margin-bottom: 1rem;
border: 1px solid transparent;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255,102,203);
} 

#bp_container img {
  max-width: 80%;
}